<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('quota_management')"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('quota_management')"
                              @filter-div-status="datatable.filterStatus = $event">

                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                              :exportExcel="true"
                              @exportExcel="getRowsExcel()">
                <b-row>
                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group
                            :label="$t('instructor_name')"
                            class="position-relative"
                        >
                            <staff-auto-suggest
                                v-model="datatable.queryParams.filter.instructor_id"></staff-auto-suggest>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox
                                type="english"
                                v-model="datatable.queryParams.filter.academic_year"
                            ></academic-years-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('semester')">
                            <semester-type-selectbox v-model="datatable.queryParams.filter.semester">
                            </semester-type-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox
                                v-model="datatable.queryParams.filter.faculty_code"
                            ></faculty-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('department')">
                            <department-selectbox
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                v-model="datatable.queryParams.filter.department_code"
                            ></department-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('program')">
                            <program-selectbox
                                :faculty_code="datatable.queryParams.faculty_code"
                                :department_code="datatable.queryParams.department_code"
                                v-model="datatable.queryParams.program_code"
                            ></program-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('course_code')">
                            <b-form-input
                                type="text"
                                :placeholder="$t('course_code')"
                                v-model="datatable.queryParams.course_code"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('campus_id')">
                            <campus-selectbox
                                v-model="datatable.queryParams.filter.campus_id"
                            ></campus-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('building_id')">
                            <building-selectbox
                                :campus_id="datatable.queryParams.filter.campus_id"
                                v-model="datatable.queryParams.filter.building_id"
                            ></building-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('classroom_')">
                            <classroom-selectbox
                                :building_id="datatable.queryParams.filter.building_id"
                                v-model="datatable.queryParams.filter.classroom_id"

                            ></classroom-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('section')">
                            <b-form-input
                                type="number"
                                :placeholder="$t('section')"
                                v-model="datatable.queryParams.filter.section"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="3">
                        <b-form-group :label="$t('quota')">
                            <b-form-input
                                type="number"
                                :placeholder="$t('quota')"
                                v-model="datatable.queryParams.filter.quota"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>

            <datatable
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                :rows="datatable.rows"
                :columns="datatable.columns"
                :query-params="datatable.queryParams"
                v-show="datatable.showTable"
                :is-loading="datatable.isLoading"
            ></datatable>
            <CommonModal ref="updateFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import QuotaManagementService from "@/services/QuotaManagementService";
import qs from "qs";
import CommonModal from "@/components/elements/CommonModal";
import UpdateForm from "./UpdateForm"
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";
import BuildingSelectbox from "@/components/interactive-fields/BuildingSelectbox";
import ClassroomSelectbox from "@/components/interactive-fields/ClassroomSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import StaffAutoSuggest from "@/components/interactive-fields/StaffAutoSuggest";
import SemesterTypeSelectbox from "../../../components/interactive-fields/SemesterTypeSelectbox";
import ElectivePoolService from "@/services/ElectivePoolService";

export default {
    components: {
        StaffAutoSuggest,
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,
        DatatableFilter,
        Datatable,
        AcademicYearsSelectbox,
        CampusSelectbox,
        BuildingSelectbox,
        UpdateForm,
        ClassroomSelectbox,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        SemesterTypeSelectbox,
    },
    metaInfo() {
        return {
            title: this.$t("quota_management"),
        };
    },
    data() {
        return {
            id: 0,
            form: {},
            building_id: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [

                            {
                                text: this.$t('edit'),
                                class: 'ri-search-eye-line',
                                permission: "section_updatequota",
                                callback: (row) => {
                                    this.showForm(row.section_id);
                                },
                            },
                        ]
                    },
                    {
                        label: this.$t('academic_year'),
                        field: 'academic_year',
                        sortable: true,
                    },
                    {
                        label: this.$t('semester'),
                        field: 'semester',
                        sortable: false,
                    },
                    {
                        label: this.$t('section'),
                        field: 'section',
                        sortable: false,
                    },
                    {
                        label: this.$t('faculty_name'),
                        field: 'faculty_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('program_name'),
                        field: 'program_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('course'),
                        field: 'course.name',
                        sortable: false,
                    },
                    {
                        label: this.$t('course_code'),
                        field: 'course.code',
                        sortable: false,
                    },
                    {
                        label: this.$t('credit'),
                        field: 'credit',
                        sortable: false,
                    },
                    {
                        label: this.$t('instructor_name'),
                        field: 'instructor.name',
                        sortable: false,
                    },
                    {
                        label: this.$t('instructor_surname'),
                        field: 'instructor.surname',
                        sortable: false,
                    },
                    {
                        label: this.$t('max_capacity'),
                        field: 'classroom.capacity',
                        sortable: false,
                    },
                    {
                        label: this.$t('quota'),
                        field: 'quota',
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            formId: 0
        }
    },
    methods: {
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },

        formClear() {
            this.formId = null
            this.formProcess = null
        },
        showForm(id) {
            this.formId = id
            this.formProcess = 'update'
            this.$refs.updateFormModal.$refs.commonModal.show()
        },
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = false;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return QuotaManagementService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        async updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        getRowsExcel() {
            return QuotaManagementService.exportExcel()
                .then((response) => {
                    this._downloadFile(response, this.$t('quota_management') + '.xlsx')
                })
                .catch(err => this.showErrors(err))
        }
    },
};
</script>

